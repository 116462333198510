import { Component, ElementRef, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { environment } from '../../../../environments/environment';
import { RouteObject } from '../../header/components/menu/menu.model';
import { Event, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { GoogleTagManagerService, UserProfile, UserService } from '../../../shared';
import { AppsService } from '../../../apps/apps.service';
import { DeepLinkApp, NavigationAnalyticInfo } from '../../../apps/app.model';
import { SegmentService } from '../../../shared/services/segment.service';
import { ProfileMenuItemService } from '../../../shared/services/profile-menu-item.service';
import { MenuV2Service } from '../menu-v2.service';
import { flyOutAnimationLeft } from '../../../shared/animation';
import { ProfileTeamMemberGuard } from '../../../shared/guards/profile-team-member.guard';
import { ProfileTeamLeaderGuard } from '../../../shared/guards/profile-team-leader.guard';
import { ProfileOfficeGuard } from '../../../shared/guards/profile-office.guard';

@Component({
    selector: 'mxc-side-navigation-v2',
    templateUrl: './side-navigation-v2.component.html',
    styleUrls: ['./side-navigation-v2.component.scss'],
    animations: [
        trigger('openClose', [
            state('open', style({
                height: 'auto',
                maxHeight: '100%',
                overflow: 'auto',
            })),
            state('closed', style({
                height: '0',
                maxHeight: '0',
                overflow: 'hidden',
            })),
            transition('open => closed', [
                animate('500ms'),
            ]),
            transition('closed => open', [
                animate('500ms'),
            ]),
        ]),
        flyOutAnimationLeft,
    ],
})
export class SideNavigationV2Component implements OnInit {
    @Input() sideNavToggle: BehaviorSubject<boolean>;
    @ViewChildren('subNavs') subNavs: QueryList<ElementRef>;
    @Input() isMobile: boolean;

    isCorporateUser = false;
    findNavItemTitle = 'Find & Refer';
    findNavItemKey = 'find-refer';
    mobileSideNavOpen: boolean;
    currentUser: UserProfile;
    selectedNav: RouteObject[];
    maxWidthMd240 = false;

    // defined in OnInit
    currentRoute = '';

    // Navigation app links
    supportApp: DeepLinkApp;
    contactsApp: DeepLinkApp;
    websitesApp: DeepLinkApp;
    emailApp: DeepLinkApp;
    educationApp: DeepLinkApp;
    dynamicNavigation: Array<RouteObject[]> = [];
    onProfileRoutes: boolean = false;
    isUsaGroup: boolean = false;

    ANALYTICS = {
        CTA_LINK_MAP: [
            { name: 'Idea Portal', cta: 'Idea Portal' },
            { name: 'URL Shortener', cta: 'URL Shortener' },
            { name: 'Agents', cta: 'Agents', url: '/roster/user/list', event: 'Referrals Opened' },
        ],
        CTA_APP_MAP: {
            'Customer Support': { cta: 'Customer Support' },
        },
        LOCATION: 'left-nav',
    };

    appNavigation: Array<RouteObject[]> = [
        [
            {
                path: '/home',
                name: 'Dashboard',
                icon: 'dashboard',
                nestedNavName: 'Dashboard',
                navKey: 'dashboard',
            },
        ],
        [
            {
                path: '/apps',
                name: 'Apps',
                icon: 'apps',
                nestedNavName: 'Apps & Tools',
                navKey: 'apps',
            },
        ],
        [
            // news navigation
            {
                path: '/content',
                name: 'All News & Resources',
                icon: 'article',
                nestedNavName: 'Resources',
                nestedNavKey: 'resources',
                navKey: 'content',
            },
            {
                path: '/content',
                params: {
                    dept: 'luxury',
                    page: 1,
                },
                name: 'The RE/MAX Collection',
                nestedNavName: 'Resources',
                nestedNavKey: 'resources',
                icon: 'article',
                navKey: 'content-luxury',
            },
            {
                path: '/content',
                params: {
                    dept: 'commercial',
                    page: 1,
                },
                name: 'RE/MAX Commercial',
                nestedNavName: 'Resources',
                nestedNavKey: 'resources',
                icon: 'article',
                navKey: 'content-commercial',
            },
            {
                path: '/content',
                params: {
                    dept: 'global',
                    page: 1,
                },
                name: 'RE/MAX Global',
                nestedNavName: 'Resources',
                nestedNavKey: 'resources',
                icon: 'article',
                navKey: 'content-global',
            },
        ],
        [
            {
                path: '#',
                name: this.findNavItemTitle,
                nestedNavName: this.findNavItemTitle,
                nestedNavKey: this.findNavItemKey,
                icon: 'person_search',
                navKey: 'find-refer'
            },
        ],
        [
            {
                path: '/favorites',
                name: 'Favorites',
                icon: 'favorite',
                nestedNavName: 'Favorites',
                navKey: 'favorites',
            },
        ],
        [
            {
                path: 'https://remax.okta.com/app/salesforce-fedid/exklww7f47ujAxQPD0x7/sso/saml?RelayState=https://remax.my.site.com/s/ideawebform?startURL=https://remax.my.site.com/s/ideawebform',
                name: 'Idea Portal',
                icon: 'lightbulb',
                nestedNavName: 'Idea Portal',
                navKey: 'idea-portal',
                isExternal: true,
            },
        ],
        [
            {
                path: '/shortener',
                name: 'URL Shortener',
                icon: 'link',
                nestedNavName: 'URL Shortener',
                navKey: 'url-shortener',
            },
        ],
        [
            {
                path: '#',
                name: 'Discover',
                icon: 'explore',
                nestedNavName: 'Discover',
                nestedNavKey: 'discover',
                parentItemOnly: true,
                navKey: 'discover',
                hideForCountry: 'USA',
            },
            {
                path: '/build-your-business',
                name: 'Business Checklist',
                icon: 'explore',
                nestedNavName: 'Discover',
                nestedNavKey: 'discover',
                navKey: 'build-your-business',
                hideForCountry: 'USA',
            },
        ],
    ];

    myProfileMenuItems: RouteObject[] = [
        {
            path: '/profile',
            name: 'Profile Overview',
            icon: 'account_circle',
            nestedNavName: 'My Profile',
            nestedNavKey: 'myProfile',
            navKey: 'my-info',
            params: {
                section: 'profile-overview',
            }
        },
        {
            path: '/profile',
            name: 'Contact Information',
            nestedNavName: 'My Profile',
            nestedNavKey: 'myProfile',
            icon: 'account_circle',
            navKey: 'contact-information',
            params: {
                section: 'contact-information',
            }
        },
        {
            path: '/profile',
            name: 'Professional Details',
            nestedNavName: 'My Profile',
            nestedNavKey: 'myProfile',
            icon: 'account_circle',
            navKey: 'professional-details',
            params: {
                section: 'professional-details',
            }
        },
        {
            path: '/profile',
            name: 'Personal Details',
            icon: 'account_circle',
            nestedNavName: 'My Profile',
            nestedNavKey: 'myProfile',
            navKey: 'personal-details',
            params: {
                section: 'personal-details',
            }
        },
        {
            path: '/profile',
            name: 'Awards',
            nestedNavName: 'My Profile',
            nestedNavKey: 'myProfile',
            icon: 'account_circle',
            navKey: 'awards',
            params: {
                section: 'awards',
            }
        },
        {
            path: '/profile',
            name: 'Privacy Options',
            nestedNavName: 'My Profile',
            nestedNavKey: 'myProfile',
            icon: 'account_circle',
            navKey: 'privacy-options',
            params: {
                section: 'privacy-options',
            }
        },
    ];

    myTeamLeader: RouteObject[] = [
        {
            path: '/profile/team',
            name: 'Team Overview',
            nestedNavName: 'My Team',
            nestedNavKey: 'myTeam',
            icon: 'people',
            navKey: 'team-overview',
            params: {
                section: 'team-overview',
            }
        },
        {
            path: '/profile/team',
            name: 'Contact Information',
            icon: 'people',
            nestedNavName: 'My Team',
            nestedNavKey: 'myTeam',
            navKey: 'team-contact-information',
            params: {
                section: 'team-contact-information',
            }
        },
        {
            path: '/profile/team/team-leads-area',
            name: 'Team Leads Area',
            nestedNavName: 'My Team',
            nestedNavKey: 'myTeam',
            icon: 'people',
            navKey: 'team-leads-area',
        },
        {
            path: '/profile/team/team-roster',
            name: 'Team Roster',
            nestedNavName: 'My Team',
            nestedNavKey: 'myTeam',
            icon: 'people',
            navKey: 'team-roster',
        },
    ];

    myTeamMember: RouteObject[] = [
        this.myTeamLeader[0],
        this.myTeamLeader[1],
        this.myTeamLeader[3]
    ];

    myOffice: RouteObject[] = [
        {
            path: '/profile/office/office-locations',
            name: 'Office Locations',
            nestedNavName: 'My Office',
            nestedNavKey: 'myOffice',
            icon: 'business',
            navKey: 'office-locations',
        },
        {
            path: '/profile/office',
            name: 'Office Overview',
            nestedNavName: 'My Office',
            nestedNavKey: 'myOffice',
            icon: 'business',
            navKey: 'office-overview',
            params: {
                section: 'office-overview',
            }
        },
        {
            path: '/profile/office',
            name: 'Contact Information',
            nestedNavName: 'My Office',
            nestedNavKey: 'myOffice',
            icon: 'business',
            navKey: 'office-contact-information',
            params: {
                section: 'office-contact-information',
            }
        },
        {
            path: '/profile/office',
            name: 'Office Details',
            nestedNavName: 'My Office',
            nestedNavKey: 'myOffice',
            icon: 'business',
            navKey: 'office-details',
            params: {
                section: 'office-details',
            }
        },
        {
            path: '/profile/office',
            name: 'Opt-Ins',
            nestedNavName: 'My Office',
            nestedNavKey: 'myOffice',
            icon: 'business',
            navKey: 'opt-ins',
            params: {
                section: 'opt-ins',
            }
        },
        {
            path: '/profile/office/office-leads-area',
            name: 'Office Leads Area',
            nestedNavName: 'My Office',
            nestedNavKey: 'myOffice',
            icon: 'business',
            navKey: 'office-leads-area',
        },
        {
            path: '/profile/office/office-roster',
            name: 'Office Roster',
            nestedNavName: 'My Office',
            nestedNavKey: 'myOffice',
            icon: 'business',
            navKey: 'office-roster',
        },
    ];

    profileNavigation: Array<RouteObject[]> = [
        this.myProfileMenuItems,
        this.myTeamLeader,
        this.myOffice,
    ];

    constructor(
        private userService: UserService,
        public router: Router,
        public menuV2Service: MenuV2Service,
        public appsService: AppsService,
        public segmentService: SegmentService,
        public profileTeamMemberGuard: ProfileTeamMemberGuard,
        public profileTeamLeaderGuard: ProfileTeamLeaderGuard,
        public profileOfficeGuard: ProfileOfficeGuard,
        private profileMenuItemService: ProfileMenuItemService,
        public gtmService: GoogleTagManagerService,
    ) {
        this.selectedNav = this.dynamicNavigation[this.menuV2Service.routeIndex];
        this.router.events.subscribe((event: Event) => {
            if (event instanceof NavigationEnd) {
                this.currentRoute = event.url;
                this.matchesFullPath();
                this.dynamicNavigation.forEach((navs, i) => {
                    navs.forEach((nav, j) => {
                        const checkUrl = this.getPathToCompare(event.url);
                        if (checkUrl.indexOf(nav.path) >= 0) {
                            this.setSelectedNav(this.dynamicNavigation.indexOf(navs));
                        }
                        this.dynamicNavigation[i][j].activeStyles = false; // un-highlight menu-items
                    });
                });
            }
        });
    }

    ngOnInit(): void {
        this.isUsaGroup = this.userService.userIsInGroup('USA');
        this.checkProfileMenuAccess();
        // This is to swap the left navigation mode between the regular navigation and the profile navigation
        if (/^\/profile/i.test(this.router.url)) {
            this.dynamicNavigation = this.profileNavigation;
            this.onProfileRoutes = true;
        } else {
            this.dynamicNavigation = this.isUsaGroup
                ? this.appNavigation
                    .map(navs => navs.filter(nav => nav.hideForCountry !== 'USA'))
                    .filter(navs => navs.length > 0)
                : this.appNavigation;
            this.onProfileRoutes = false;
        }

        // because this component is loaded via the route-view.component from various modules during routing,
        // this will be initialized for each module
        this.userService.getMe().subscribe(res => {
            this.currentUser = res;
            this.isCorporateUser = this.currentUser.groups?.length ? this.currentUser.groups.includes('Corporate') : false;
            if (!/^\/profile/i.test(this.router.url)) {
                this.getFindNavigations();
                // called within getMe as the Find/Refer menu item depends on userService data
                if (!this.menuV2Service.sectionSubMenuToggle.length) {
                    this.dynamicNavigation.forEach((item, index) => {
                        this.menuV2Service.sectionSubMenuToggle[index] = false;
                    });
                }
            }
        });

        this.mapNavigationAppLinks().then(() => {
            if (this.supportApp && !this.onProfileRoutes) {
                // Find the index of the array containing 'URL Shortener'
                const urlShortenerIndex = this.dynamicNavigation.findIndex(navArray =>
                    navArray.some(navItem => navItem.name === 'URL Shortener')
                );

                // Insert the supportApp just before 'URL Shortener'
                if (urlShortenerIndex !== -1) {
                    this.dynamicNavigation.splice(urlShortenerIndex, 0, [
                        {
                            path: this.supportApp.link || this.supportApp.app.linkUrl,
                            name: this.supportApp.navDisplayName,
                            icon: 'help_outline',
                            nestedNavName: this.supportApp.navDisplayName,
                            navKey: this.supportApp.navKey,
                            isExternal: true,
                        },
                    ]);
                }
            }
            this.dynamicNavigation.forEach(navs => {
                navs.forEach(nav => {
                    const checkUrl = this.getPathToCompare(this.router.url);
                    if (checkUrl.indexOf(nav.path) >= 0) {
                        this.menuV2Service.currentRouteIndex = this.dynamicNavigation.indexOf(navs);
                    }
                    // sets the initial selectedNav on init of component
                    if (checkUrl.startsWith(nav.path)) {
                        this.selectedNav = navs;
                    }
                });
                this.matchesFullPath();
            });
        });


        this.sideNavToggle.subscribe(open => {
            this.mobileSideNavOpen = open;
        });

        this.maxWidthMd240 = this.router.url === '/shortener' ? true : false;

        // profile pages, highlight emitted menu-items from scrollHandler
        this.profileMenuItemService.currentAnchor.subscribe(menuItemObj => {
            const entities = ['agent', 'team', 'office'];
            this.dynamicNavigation.forEach((routes, i) => {
                routes.forEach((route, r) => {
                    // highlight emitted menu-item
                    if (menuItemObj?.index === r && menuItemObj?.entity === entities[i]) {
                        this.dynamicNavigation[i][r].activeStyles = true;
                        this.dynamicNavigation[i][r].activeRouteMatch = true;
                    } else {
                        this.dynamicNavigation[i][r].activeStyles = false;
                        this.dynamicNavigation[i][r].activeRouteMatch = false;
                    }
                });
            });
        });
    }

    triggerGTMEvent(text: string): void {
        this.gtmService.clickEvent( {
            event_name: 'nav',
            element_text: text,
            type: 'left_nav',
        });
    }

    checkProfileMenuAccess(): void {
    // do they have access to team and office?
        this.profileNavigation = this.calculateProfileMenuAccess({
            teamMember: this.profileTeamMemberGuard.canDisplayProfileMenuOption(),
            teamLead: this.profileTeamLeaderGuard.canDisplayProfileMenuOption(),
            officeUser: this.profileOfficeGuard.canDisplayProfileMenuOption(),
        });
    }

    calculateProfileMenuAccess(userProfilePermissions: { teamMember: boolean; teamLead: boolean; officeUser: boolean }): RouteObject[][] {
        const menuItems = [this.myProfileMenuItems];

        if (userProfilePermissions.teamLead && userProfilePermissions.teamMember) {
            // @ts-ignore
            menuItems.push(this.myTeamLeader);
        } else if (userProfilePermissions.teamMember) {
            // @ts-ignore
            menuItems.push(this.myTeamMember);
        }

        if (userProfilePermissions.officeUser) {
            menuItems.push(this.myOffice);
        }

        return menuItems;
    }

    handleSubNavClick(subNav): void {
    // if on profile routes, send subNav to profileMenuItemService for scroll handling
        if (/^\/profile/i.test(this.router.url)) {
            this.profileMenuItemService.setClickedMenuItem(subNav);
        }
    }

    mapNavigationAppLinks(): Promise<void> {
        return new Promise<void>((resolve) => {
            this.appsService.allApps.subscribe(res => {
                res.data.forEach(app => {
                    // Support
                    if (app.id === environment.APP_DEEP_LINKS.SUPPORT.APPID) {
                        this.supportApp = {
                            link: environment.APP_DEEP_LINKS.SUPPORT.LINK || app.linkUrl,
                            navDisplayName: environment.APP_DEEP_LINKS.SUPPORT.NAME,
                            app: app,
                            navKey: environment.APP_DEEP_LINKS.SUPPORT.NAV_KEY
                        };
                    }
                    // Contacts
                    if (app.id === environment.APP_DEEP_LINKS.CONTACTS.APPID) {
                        this.contactsApp = {
                            link: environment.APP_DEEP_LINKS.CONTACTS.LINK || app.linkUrl,
                            navDisplayName: environment.APP_DEEP_LINKS.CONTACTS.NAME,
                            app: app,
                            navKey: environment.APP_DEEP_LINKS.CONTACTS.NAV_KEY
                        };
                    }
                    // Website
                    if (app.id === environment.APP_DEEP_LINKS.WEBSITE.APPID) {
                        this.websitesApp = {
                            link: environment.APP_DEEP_LINKS.WEBSITE.LINK || app.linkUrl,
                            navDisplayName: environment.APP_DEEP_LINKS.WEBSITE.NAME,
                            app: app,
                            navKey: environment.APP_DEEP_LINKS.WEBSITE.NAV_KEY
                        };
                    }
                    // Email
                    if (app.id === environment.APP_DEEP_LINKS.EMAIL.APPID) {
                        this.emailApp = {
                            link: environment.APP_DEEP_LINKS.EMAIL.LINK || app.linkUrl,
                            navDisplayName: environment.APP_DEEP_LINKS.EMAIL.NAME,
                            app: app,
                            navKey: environment.APP_DEEP_LINKS.EMAIL.NAV_KEY
                        };
                    }
                    // Education
                    if (app.id === environment.APP_DEEP_LINKS.EDUCATION.APPID) {
                        this.educationApp = {
                            link: environment.APP_DEEP_LINKS.EDUCATION.LINK || app.linkUrl,
                            navDisplayName: environment.APP_DEEP_LINKS.EDUCATION.NAME,
                            app: app,
                            navKey: environment.APP_DEEP_LINKS.EDUCATION.NAV_KEY
                        };
                    }
                });
                resolve();
            });
        });
    }


    getFindNavigations(): void {
        let navItem: Array<RouteObject>;
        if (!this.isCorporateUser) {
            if (this.currentUser.class === 'INDIV' || this.currentUser.class === 'STAFF' || this.currentUser.class === 'TEAM') {
                navItem = [
                    {
                        path: '/roster/user/list',
                        name: 'Find/Refer an Agent',
                        icon: 'person_search',
                        nestedNavName: this.findNavItemTitle,
                        nestedNavKey: this.findNavItemKey,
                        navKey: 'find-agent'
                    },
                    {
                        path: `/roster/user/${this.userService.user.id}/referrals/received`,
                        name: 'Received Referrals',
                        icon: 'person_search',
                        nestedNavName: this.findNavItemTitle,
                        nestedNavKey: this.findNavItemKey,
                        navKey: 'referrals-received'
                    },
                    {
                        path: '/roster/office/list',
                        params: {
                            includeServiceArea: 1,
                            officeType: 'OFFICE',
                        },
                        name: 'Find an Office',
                        icon: 'person_search',
                        nestedNavName: this.findNavItemTitle,
                        nestedNavKey: this.findNavItemKey,
                        navKey: 'find-office'
                    },
                ];
            } else if (this.currentUser.class === 'APVCNT' || this.currentUser.class === 'APVSUP') {
                navItem = [
                    {
                        path: '/approved-supplier/user',
                        name: 'Find Agents',
                        icon: 'person_search',
                        nestedNavName: 'Find',
                        navKey: 'find-agent-approved-supplier'
                    },
                ];
            } else if (this.currentUser.class === 'OFFICE') {
                navItem = [
                    {
                        path: '/roster/office/list',
                        name: 'Find Offices',
                        icon: 'person_search',
                        nestedNavName: this.findNavItemTitle,
                        nestedNavKey: this.findNavItemKey,
                        navKey: 'find-office'
                    },
                ];
            }
        } else {
            navItem = [
                {
                    path: '/roster/user/list',
                    name: 'Agents',
                    nestedNavName: this.findNavItemTitle,
                    nestedNavKey: this.findNavItemKey,
                    icon: 'person_search',
                    navKey: 'find-agent'
                },
                {
                    path: '/approved-supplier/user',
                    name: 'Find Agents (Approved Suppliers)',
                    nestedNavName: this.findNavItemTitle,
                    nestedNavKey: this.findNavItemKey,
                    icon: 'person_search',
                    navKey: 'find-agent-approved-supplier'
                },
                {
                    path: '/roster/office/list',
                    name: 'Offices',
                    icon: 'person_search',
                    navKey: 'find-office'
                },
                {
                    path: '/approved-supplier/office',
                    name: 'Find Offices (Approved Supplier)',
                    nestedNavName: this.findNavItemTitle,
                    nestedNavKey: this.findNavItemKey,
                    icon: 'person_search',
                    navKey: 'find-office-approved-supplier'
                },
                {
                    path: '/roster/corporate/list',
                    name: 'Corporate Staff',
                    nestedNavName: this.findNavItemTitle,
                    nestedNavKey: this.findNavItemKey,
                    icon: 'person_search',
                    navKey: 'find-agent-corporate'
                },
            ];
            if (this.currentUser.groups.includes('Super Admin')) {
                navItem.push({
                    path: `/roster/user/${this.userService.user.id}/referrals/received`,
                    name: 'Received Referrals',
                    icon: 'person_search',
                    nestedNavName: this.findNavItemTitle,
                    nestedNavKey: this.findNavItemKey,
                    navKey: 'referrals-received'
                });
            }
        }
        this.dynamicNavigation = this.dynamicNavigation.map((item, index) => {
            if (item[0].name === this.findNavItemTitle) {
                item = navItem;
            }
            return item;
        });
    }

    getPathToCompare(url: string): string {
        if (url.includes('/search?search')) {
            return '/home';
        }
        if (url.includes('/roster/user?search')) {
            return '/roster/user/list';
        }
        if (url.includes('/roster/corporate?search')) {
            return '/roster/corporate/list';
        }
        if (url.includes('/roster/office?search')) {
            return '/roster/office/list';
        }
        return url;
    }

    setSelectedNav(index: number): void {
        this.selectedNav = this.dynamicNavigation[index];
        this.menuV2Service.routeIndex = index;
    }

    navigateToRoute(path: string): void {
        this.router.navigateByUrl(path);
    }

    trackClick(name: string): void {
        const cta = this.ANALYTICS.CTA_LINK_MAP.find(i => i.name.toUpperCase() === name.toUpperCase());
        if (cta) {
            this.segmentService.segmentTrackSideNavClick({
                location: this.ANALYTICS.LOCATION,
                cta: cta.cta,
            });
        }
    }

    getAdditionalAnalytics(appName: string): NavigationAnalyticInfo {
        return {
            cta: appName,
            location: this.ANALYTICS.LOCATION,
        };
    }

    determineAnalytics(route: RouteObject): void {
        const cta = this.ANALYTICS.CTA_LINK_MAP.find(i => i.name.toUpperCase() === route.name.toUpperCase());
        if (cta && cta.event) {
            this.segmentService.track(cta.event, {
                cta: cta.name,
                destination_url: cta.url,
                location: this.ANALYTICS.LOCATION,
            });
        }
    }

    toggleSubNav(sectionId: number, subMenu: boolean = false): void {
        if (subMenu) {
            this.menuV2Service.sectionSubMenuToggle[sectionId] = true;
            return;
        }
        this.menuV2Service.sectionSubMenuToggle[sectionId] = !this.menuV2Service.sectionSubMenuToggle[sectionId];
    }

    closeSideMenu(): void {
        this.sideNavToggle.next(false);
    }

    matchesFullPath(): void {
        this.dynamicNavigation.forEach((routes, i) => {
            routes.forEach((route, r) => {
                this.dynamicNavigation[i][r].activeRouteMatch = (this.router.url === this.buildFullRouteUrl(route));
            });
        });
    }

    buildFullRouteUrl(route: RouteObject): string {
        let uriQuery = '';
        if (route.params) {
            const paramKeys = Object.keys(route.params);
            paramKeys.forEach(param => {
                uriQuery.length ? uriQuery += '&' : uriQuery = '?';
                uriQuery += `${param}=${route.params[param]}`;
            });
        }
        return `${route.path}${uriQuery}`;
    }

}

