import {
    Component,
    ViewEncapsulation,
    Renderer2,
    OnInit,
    HostListener,
    Input,
    ElementRef,
    ViewChild,
} from '@angular/core';
import { environment } from 'environments/environment';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import {
    fadeInAnimation,
    flyOutAnimationRight,
} from '../../../shared/animation';
import { LogoutModalComponent, ChangeUserComponent } from '../..';
import { GoogleTagManagerService, UserProfile, UserService } from '../../../shared';
import { BehaviorSubject } from 'rxjs';
import { AppsService } from '../../../apps/apps.service';
import {
    App,
    DeepLinkApp,
    NavigationAnalyticInfo,
} from '../../../apps/app.model';
import { SegmentService } from '../../../shared/services/segment.service';

@Component({
    selector: 'mxc-header-v2',
    templateUrl: './header-v2.component.html',
    styleUrls: ['./header-v2.component.scss'],
    animations: [fadeInAnimation, flyOutAnimationRight],
    encapsulation: ViewEncapsulation.None,
})
export class HeaderV2Component implements OnInit {
    @Input() sideNavToggle: BehaviorSubject<boolean>;
    @Input() profileToggle: BehaviorSubject<boolean>;
    isMenuActive = false;
    navbarLogo: string = environment.IMAGES.logo.navbar;
    billPayUrl: string = environment.BILLING_PAYMENT_URL;
    windowWidth: number;
    mobile: boolean;
    currentUser: UserProfile;
    profileOpen = false;
    openModal: NgbModalRef;
    closeIcon = faTimes;
    searchForm: FormGroup;
    MOBILE_BREAKPOINT = 992;
    showSearchOnMobile = false;
    showChangeUser = false;

    // handling for closing the subnav
    navigated = false;
    insideClick = false;

    ANALYTICS = {
        LOCATION: 'top-nav',
    };

    constructor(
        public renderer: Renderer2,
        private router: Router,
        private fb: FormBuilder,
        private userService: UserService,
        private modalService: NgbModal,
        public segmentService: SegmentService,
        public gtmService: GoogleTagManagerService,
    ) {
        this.searchForm = this.fb.group({
            term: ['', Validators.required],
        });
    }

    ngOnInit(): void {
        this.setOverflow();
        this.windowWidth = window.innerWidth;
        this.mobile = this.windowWidth <= this.MOBILE_BREAKPOINT;
        this.userService.getMe().subscribe(res => {
            this.currentUser = res;
            this.showChangeUser =
                this.currentUser.groups.includes('Super Admin') ||
                this.currentUser.groups.includes('Impersonators');
        });

        this.sideNavToggle.subscribe(open => {
            if (!open) {
                this.isMenuActive = false;
                this.setOverflow();
            }
        });
    }

    submitSearch(): void {
        const searchTerm = this.searchForm.get('term').value;
        if (searchTerm) {
            const encodedSearchTerm = encodeURIComponent(searchTerm);
            this.router.navigate(['/search'], {
                queryParams: { search: encodedSearchTerm },
            });
        }
    }


    setOverflow(): void {
        this.renderer.setStyle(
            document.body,
            'overflow',
            this.isMenuActive || this.profileOpen ? 'hidden' : 'visible'
        );
    }

    @HostListener('window:resize', ['$event'])
    onResize(event): void {
        this.windowWidth = window.innerWidth;
        this.mobile = this.windowWidth <= this.MOBILE_BREAKPOINT;
    }

    @HostListener('click')
    clickInside(): void {
        this.insideClick = true;
    }

    @HostListener('document:click')
    clickout(): void {
        if (!this.insideClick) {
            // clicked outside
            this.profileOpen = false;
        }
        this.insideClick = false;
    }

    toggleMobileSearch(focus = false): void {
        this.showSearchOnMobile = !this.showSearchOnMobile;
        if (focus) {
            this.searchForm.get('term').reset();
            setTimeout(() =>
                this.renderer.selectRootElement('#global-search-input').focus()
            );
        }
    }

    toggleMenu(): void {
        this.isMenuActive = !this.isMenuActive;
        this.sideNavToggle.next(this.isMenuActive);
        this.setOverflow();
    }

    toggleProfile(authCheck = false): void {
        if (authCheck) {
            this.userService.continueOrLogout();
        }
        this.profileOpen = !this.profileOpen;
        if (this.mobile) {
            this.setOverflow();
            this.profileToggle.next(this.profileOpen);
            if (this.profileOpen) {
                this.sideNavToggle.next(false);
            }
        }
    }

    getEditUrl(): string {
        this.userService.continueOrLogout();
        return `${environment.PROFILE_URL}?mcid=${this.currentUser.id}`;
    }

    openLogoutModal(): void {
        this.openModal = this.modalService.open(LogoutModalComponent);
    }

    userInitials(user: UserProfile): string {
        return (
            user.firstName.charAt(0).toUpperCase() +
            user.lastName.charAt(0).toUpperCase()
        );
    }

    goToApp(app: App, additionalAnalytics: NavigationAnalyticInfo): void {
        this.segmentService.segmentTrackAppOpened(app, additionalAnalytics);
        window.open(app.linkUrl, '_blank');
    }

    openChangeUserModal(e): void {
        if (e) {
            e.preventDefault();
        }
        this.openModal = this.modalService.open(ChangeUserComponent);
    }

    revertUser(): void {
        this.userService.revertUser().subscribe(() => window.location.reload());
    }
}
