<div id="layout-container" class="min-vh-100">
    <div class="flex-row min-vh-100">
        <div class="w-100 fixed-header">
            <mxc-header-v2 [sideNavToggle]="sideNavToggle" [profileToggle]="profileToggle"></mxc-header-v2>
        </div>
        <div class="d-flex layout-v2-body position-relative min-vh-100">
            <div class="side-navigation-wrapper">
                <mxc-side-navigation-v2 [sideNavToggle]="sideNavToggle" [isMobile]="deviceDetectorService.isMobile"></mxc-side-navigation-v2>
            </div>
            <div class="layout-v2-content w-100">
                <router-outlet></router-outlet>
            </div>
            <div id="overlay" *ngIf="deviceDetectorService.isMobile" class="w-100 h-100 position-absolute" [ngStyle]="{display: sideNavOpen || profileOpen ? 'block' : 'none'}"></div>
        </div>
    </div>
</div>
